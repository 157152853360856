import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import ArticleGrid from "../components/article-grid"
import {
  Section,
  Pagehead,
  PageTitle,
  Breadcrumbs,
  CallToAction,
} from "../components/shared"

const AuthorArticles = ({ pageContext: { author } }) => {
  const authorName = `${author.firstName} ${author.lastName}`
  const pageTitle = `Artikel von ${authorName}`

  return (
    <Layout>
      <SEO title={pageTitle} />
      <Pagehead bottomMargin>
        <PageTitle>{pageTitle}</PageTitle>
        <CallToAction to="/magazin">zum Magazin</CallToAction>
        <Breadcrumbs
          nodes={[{ title: "Team", path: "/team" }, { title: authorName }]}
        />
      </Pagehead>
      <Section>
        <ArticleGrid articles={author.article || []} />
      </Section>
      <Contact />
    </Layout>
  )
}

export default AuthorArticles
